import * as React                  from "react";
import Layout                      from "../components/layout"
import { useState }                from "react";
import { SiFacebook, SiInstagram } from "react-icons/si";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";

import '../styles/contact.scss';

function b64DecodeUnicode(str) {
	return decodeURIComponent(atob(str).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
}

// markup
const ContactPage = () => {
	const [isEmailVisible, setIsEmailVisible] = useState(false);
	const [isPhoneVisible, setIsPhoneVisible] = useState(false);

	const email64 = 'aXRpby5hbmRyemVqLndvam5hckBnbWFpbC5jb20=';
	const number64 = 'NjAxIDQ2MCA3MTA=';

	return (
		<Layout title="Jędrek Wojnar F.o.t.o.g.r.a.f.i.a | Kontakt">
			<div>
				<h1>Kontakt</h1>

				<p className={'contact-row'}><FaEnvelope className={'contact-icon'}/> &#160; {isEmailVisible ? b64DecodeUnicode(email64) : <button className={'contact-button'} onClick={() => setIsEmailVisible(true)}>Poproś o adres email</button>}</p>
				<p className={'contact-row'}><FaPhoneAlt className={'contact-icon'}/> &#160; {isPhoneVisible ? b64DecodeUnicode(number64) : <button className={'contact-button'} onClick={() => setIsPhoneVisible(true)}>Poproś o numer</button>}</p>
				<p className={'contact-row'}><SiFacebook className={'contact-icon'}/> &#160; <a style={{color: "inherit"}} href={'https://www.facebook.com/jedrek.woynar/'}>facebook.com/jedrek.woynar</a></p>
				<p className={'contact-row'}><SiInstagram className={'contact-icon'}/> &#160; <a style={{color: "inherit"}} href={'https://www.instagram.com/andy.wojnar/'}>instagram.com/andy.wojnar</a></p>
			</div>
		</Layout>
	)
}

export default ContactPage
